import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

const HomePage = () => {
  return (
    <Box>
      <Grid container columns={{ xs: 1, md: 2, lg: 3 }}>
        <Grid item xs={1}>
          <Box>
            <Typography variant="h3">Hello</Typography>
            <Typography variant="h4" sx={{ paddingBottom: 2 }}>
              We are nonetype ab
            </Typography>
            <Typography>
              We are a Swedish engineering consultancy supporting large and
              small organisations.
            </Typography>
            <Box sx={{ paddingBottom: [1, 2] }} />

            <Typography>
              Today our focus is on software development and climate
              sustainability.
            </Typography>
            <Box sx={{ paddingBottom: [1, 2] }} />

            <Stack direction="row" sx={{ marginY: 2 }}>
              <Link href="mailto:info@nonetype.se">
                <IconButton aria-label="email">
                  <AlternateEmailIcon />
                </IconButton>
              </Link>
              <Link href="https://www.linkedin.com/company/nonetype">
                <IconButton aria-label="linkedin">
                  <LinkedInIcon />
                </IconButton>
              </Link>
              <Link href="https://github.com/nonetype-ab">
                <IconButton aria-label="github">
                  <GitHubIcon />
                </IconButton>
              </Link>
            </Stack>
          </Box>
        </Grid>

        <Grid
          item
          xs={1}
          lg={2}
          sx={{
            backgroundImage: `url('https://images.unsplash.com/photo-1509356843151-3e7d96241e11?auto=format&w=1024&dpr=${window.devicePixelRatio}')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            paddingBottom: "40%",
            clipPath: {
              xs: "polygon(0% 0%, 100% 5%, 100% 100%, 0% 95%)",
              md: "polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%)",
            },
          }}
        ></Grid>
      </Grid>
    </Box>
  );
};

export default HomePage;
