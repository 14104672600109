import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

const DevelopmentPage = () => {
  return (
    <Box>
      <Grid container columns={{ xs: 1, md: 2, lg: 3 }}>
        <Grid item xs={1}>
          <Box>
            <Typography variant="h3">Hello, I’m Loa.</Typography>
            <Typography variant="h4" sx={{ paddingBottom: 2 }}>
              Software Developer
            </Typography>
            <Typography>
              I'm an avid software developer and systems engineer.
            </Typography>
            <Box sx={{ paddingBottom: [1, 2] }} />

            <Typography>
              I'm pragmatic and continuously reevaluate status-quo to keep an
              holistic view. I try to step back and look at the greater picture
              to make sure the projects I'm involved in isn't fixing symptoms
              rather than the real issues whatever they are cultural, process or
              technical. Don't fix artificial issues or systems that aren't
              used.
            </Typography>
            <Box sx={{ paddingBottom: [1, 2] }} />

            <Stack direction="row" sx={{ marginY: 2 }}>
              <Link href="mailto:loa@nonetype.se">
                <IconButton aria-label="email">
                  <AlternateEmailIcon />
                </IconButton>
              </Link>
              <Link href="https://www.linkedin.com/in/carlloaodin">
                <IconButton aria-label="linkedin">
                  <LinkedInIcon />
                </IconButton>
              </Link>
              <Link href="https://github.com/loa">
                <IconButton aria-label="github">
                  <GitHubIcon />
                </IconButton>
              </Link>
            </Stack>
          </Box>
        </Grid>

        <Grid
          item
          xs={1}
          lg={2}
          sx={{
            backgroundImage: `url('https://images.unsplash.com/photo-1519389950473-47ba0277781c?auto=format&w=1024&dpr=${window.devicePixelRatio}')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            paddingBottom: "40%",
            clipPath: {
              xs: "polygon(0% 0%, 100% 5%, 100% 100%, 0% 95%)",
              md: "polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%)",
            },
          }}
        ></Grid>
      </Grid>

      <Box sx={{ paddingBottom: [5, 10] }} />

      <Grid
        container
        columns={{ xs: 1, md: 2 }}
        direction={{ xs: "column-reverse", md: "row" }}
      >
        <Grid
          item
          xs={1}
          sx={{
            backgroundImage:
              "url('https://www.gravatar.com/avatar/c8cb38f8457bd1cb320f904b240c3064.jpg?s=600')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            paddingBottom: ["100%", "100%", "50%"],
            clipPath: {
              xs: "polygon(0% 0%, 100% 5%, 100% 100%, 0% 95%)",
              md: "polygon(0% 0%, 90% 0%, 100% 100%, 0% 100%)",
            },
          }}
        ></Grid>

        <Grid item xs={1}>
          <Box
            sx={{
              paddingBottom: {
                xs: 5,
                md: 0,
              },
              paddingLeft: {
                md: 2,
              },
            }}
          >
            <Typography variant="h3" sx={{ paddingBottom: 2 }}>
              Who am I?
            </Typography>
            <Box>
              I am developer, tinkerer, 3d printer, aviator, hiker, skater,
              skier and beer lover. I live to experience and learn from many
              walks of life.
            </Box>
            <Box sx={{ paddingBottom: [1, 2] }} />

            <Box>
              I thrive when I get to be part of improvements, building things at
              work and in my spare time. Building proof of concepts and
              prototypes has sticked with me from childhood, the projects have
              only grown more ambitious.
            </Box>
            <Box sx={{ paddingBottom: [1, 2] }} />

            <Box>
              My strength is that I focus to continuously improve myself,
              increase my capacity to build great things while being the best
              team player.
            </Box>
            <Box sx={{ paddingBottom: [1, 2] }} />

            <Box>
              You have a great team? Exciting project? Need a extra hand? Get in
              touch!
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ paddingBottom: [5, 10] }} />
    </Box>
  );
};

export default DevelopmentPage;
