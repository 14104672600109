import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { ReactComponent as Logo } from '../logo.svg';

const TopBar = () => {
  let location = useLocation();
  let navigate = useNavigate();

  const handleChange = (event, newValue) => {
    navigate(newValue);
  };

  return (
    <AppBar position="static" elevation={0} color="transparent" sx={{
      paddingTop: 2,
      paddingBottom: 2,
    }}>
      <Toolbar disableGutters>
        <Box height="20%" sx={{ color: 'primary.main', paddingRight: 2 }}>
          <Logo width="60px" />
        </Box>

        <Typography
          noWrap
          component="div"
          sx={{
            fontSize: '1.6em',
            display: ['none', null, 'flex'],
            paddingBottom: '0.6rem',
          }}
        >
          nonetype ab
        </Typography>

        <Box sx={{ display: ['none', null, 'flex'], flexGrow: 1 }} />

        <Tabs onChange={handleChange} value={location.pathname} variant="scrollable">
          <Tab label="home" value="/" sx={{textTransform: "none" }} />
          <Tab label="development" value="/development" sx={{textTransform: "none" }} />
          <Tab label="sustainability" value="/sustainability" sx={{textTransform: "none" }} />
          <Tab label="certification" value="/certification" sx={{textTransform: "none" }} />
        </Tabs>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
