import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import "@fontsource/ubuntu"

import HomePage from "./pages/home";
import SustainabilityPage from "./pages/sustainability";
import DevelopmentPage from "./pages/development";
import ContactPage from "./pages/contact";
import CertificationPage from "./pages/certification";
import TopBar from "./components/topbar";

const theme = createTheme({
  typography: {
    fontFamily: '"Ubuntu", san-serif',
  },
  palette: {
    type: "light",
    primary: {
      main: "#212121",
    },
    secondary: {
      main: "#cfd8dc",
    },
  },
});

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Router>
          <Box className="App">
            <Container fixed>
              <TopBar />
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="sustainability" element={<SustainabilityPage />} />
                <Route path="development" element={<DevelopmentPage/>} />
                <Route path="contact" element={<ContactPage />} />
                <Route path="certification" element={<CertificationPage />} />
                <Route path="*" element={<Box>no match</Box>} />
              </Routes>
            </Container>
          </Box>
        </Router>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
