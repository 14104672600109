import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import { Link } from "react-router-dom";

const questions = [
  {
    id: 'start',
    title: 'Start',
    description: 'Notera att olika versioner av listade certifieringssystem kan ha avvikelser.',
    options: [],
  },
  {
    id: 'project_type',
    title: 'Projektform',
    description: 'Vilken form av projekt är ni intresserade att certifiera?',
    options: [
      {id: 'new', label: 'Nyproduktion'},
      {id: 'existing', label: 'Ombyggnation'},
    ],
  },
  {
    id: 'property_type',
    title: 'Fastighetstyp',
    description: 'Vissa certifieringssystem gäller enbart för bostadsprojekt.',
    options: [
      {id: 'commercial', label: 'Kommersiella lokaler'},
      {id: 'residential', label: 'Bostäder'},
      {id: 'education', label: 'Skollokaler'},
    ],
  },
  {
    id: 'management_system',
    title: 'Ledningssystem',
    description: 'Finns det inom fastighetsägarens organisation tydliga verksamhetsprocesser som styr tidiga planeringsskeden, projektering, byggproduktion, överlämning och förvaltning?',
    options: [
      {id: 'yes', label: 'Ja'},
      {id: 'no', label: 'Nej'},
    ],
  },
  {
    id: 'water',
    title: 'Vattenmätning',
    description: 'Finns det permanenta vattenmätare som mäter den totala dricksvattensanvändningen för byggnaden och tillhörande mark?',
    options: [
      {id: 'yes', label: 'Ja'},
      {id: 'no', label: 'Nej'},
    ],
  },
  {
    id: 'done',
    title: 'Klar',
    description: <Box>Vi har nu sorterat certifieringssystemen efter dina val. <Link to="/sustainability">För mer information kontakta gärna oss</Link>.</Box>,
    options: [],
  },
];

const certifications = [
  {
    id: 'leed',
    title: 'Leed',
    description: 'Leed är ett internationellt certifieringssystem från USA.',
    options: [
      {
        id: 'start',
      },
      {
        id: 'project_type',
        options: ['new', 'existing'],
      },
      {
        id: 'property_type',
        options: ['commercial', 'residential', 'education'],
      },
      {
        id: 'management_system',
        options: ['yes', 'no'],
      },
      {
        id: 'water',
        description: 'Leed kräver permanent installerade vattenmätare som skallkrav. Mätardata måste sammanställas till månatliga och årliga sammanställningar.',
        options: ['yes'],
      },
    ],
  },
  {
    id: 'miljobyggnad',
    title: 'Miljöbyggnad',
    description: 'Miljöbyggnad är ett svenskt certifieringssystem som säkerställer att fastigheten har god energianvändning, innemiljö och materialval.',
    options: [
      {
        id: 'start',
      },
      {
        id: 'project_type',
        options: ['new', 'existing'],
      },
      {
        id: 'property_type',
        options: ['commercial', 'residential', 'education'],
      },
      {
        id: 'management_system',
        options: ['yes', 'no'],
      },
      {
        id: 'water',
        options: ['yes', 'no'],
      },
    ],
  },
  {
    id: 'breeam',
    title: 'Breeam',
    description: 'Breeam är certifiering för infrastruktur och byggnadsprojekt från Storbritannien.',
    options: [
      {
        id: 'start',
      },
      {
        id: 'project_type',
        options: ['new', 'existing'],
      },
      {
        id: 'property_type',
        options: ['residential', 'commercial', 'education'],
      },
      {
        id: 'management_system',
        description: 'Breeam kräver tydligt systematiskt processarbete för projektet från tidigt skede av projektering till överlämning till förvaltning.',
        options: ['yes'],
      },
      {
        id: 'water',
        options: ['yes', 'no'],
      },
    ],
  },
  {
    id: 'svanen',
    title: 'Svanen',
    description: 'Svanen ställer krav på energianvändning, kemiska produkter, byggprodukter/-varor och en rad innemiljöfaktorer som är relevanta för människors hälsa och för miljön. Dessutom ställer Svanen krav på kvalitetsstyrning i byggprocessen och på överlämnandet av byggnaden till de boende och förvaltning/drift.',
    options: [
      {
        id: 'start',
      },
      {
        id: 'project_type',
        description: 'Svanen certifierar enbart nya fastigheter.',
        options: ['new'],
      },
      {
        id: 'property_type',
        description: 'Svanen certifiering gäller idag endast för småhus, flerbostadshus och förskolebyggnader.',
        options: ['residential', 'education'],
      },
      {
        id: 'management_system',
        description: 'Svanen kräver tydligt systematiskt processarbete för projektet från tidigt skede av projektering till överlämning till förvaltning.',
        options: ['yes'],
      },
      {
        id: 'water',
        options: ['yes', 'no'],
      },
    ],
  },
];

const CertificationList = ({step, choices}) => {
  let sortedCertifications = [];

  certifications.forEach((certification, index) => {
    // shallow copy
    let cert = Object.assign({note: ''}, certification);

    for (let i = 0; i < step; i++) {
      if (choices[i] === '') {
        continue;
      }

      if (!cert.options[i].options.includes(choices[i])) {
        // certification did not pass an earlier step
        return
      }
    }

    if (choices[step] !== '' && !cert.options[step].options.includes(choices[step])) {
      cert.note = cert.options[step].description;
    }

    sortedCertifications.push(cert);
  });

  sortedCertifications.sort((a, b) => {
    return ('' + a.title).localeCompare(b.title);
  });

  return (
    <React.Fragment>
    {sortedCertifications.map((certification, index) => {
      return (
        <Paper key={certification.id} sx={{ paddingX: 2, paddingY: 2, marginTop: [1, 2] }}>
          <Typography variant="h5" sx={{ paddingBottom: 1 }}>
            { certification.title }
          </Typography>
          <Typography sx={{ marginBottom: 2 }}>
            { certification.description }
          </Typography>
          {
            (certification.note !== '') &&
              <Alert severity="warning" key={index}>
                { certification.note }
              </Alert>
          }
        </Paper>
      );
    })}
    </React.Fragment>
  );
};

const CertificationPage = () => {
  const [step, setStep] = useState(0);
  const [choices, setChoices] = React.useState(Array.from(questions, q => {
    return (q.options.length > 0) ? q.options[0]['id'] : '';
  }) );

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleStep = (index) => {
    setStep(index);
  };

  const handleOption = (event) => {
    let ch = [...choices];
    ch[step] = event.target.value;
    setChoices(ch);
  };

  return (
    <Box>
      <Grid container columns={{ xs: 2, md: 6 }}>
        <Grid item xs={1} />
        <Grid item xs={2} md={6}>
          <Box>
            <Typography variant="h3">Byggnadscertifiering</Typography>
            <Typography variant="h4">Vilket passar ditt projekt?</Typography>
          </Box>

          <Box sx={{ paddingBottom: 4}} />

          <Paper sx={{ paddingX: 2, paddingY: 2 }}>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Stepper activeStep={step} sx={{ paddingY: 3, flexGrow: 1 }}>
                {questions.map((question, index) => (
                  <Step key={question.id} completed={step > index}>
                    <StepButton color="inherit" onClick={() => handleStep(index)}>
                      <StepLabel>{question.title}</StepLabel>
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Box>

            <Box sx={{
              paddingX: {
                xs: 0,
                md: 2,
              },
            }}>
              <Typography variant="h5" sx={{ paddingBottom: 1 }}>
                { questions[step].title }
              </Typography>
              <Typography sx={{ paddingBottom: 3 }}>
                { questions[step].description }
              </Typography>

              <RadioGroup
                value={choices[step]}
                onChange={handleOption}
                sx={{ paddingLeft: 3 }}
              >
                {questions[step].options.map((option, index) => {
                  return (
                    <FormControlLabel key={option.id} value={option.id} control={<Radio />} label={option.label} />
                  );
                })}
              </RadioGroup>
            </Box>


            <MobileStepper
              variant="progress"
              steps={questions.length}
              activeStep={step}
              position="static"
              backButton={
                <Button
                  color="inherit"
                  disabled={step === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  <KeyboardArrowLeft /> Back
                </Button>
              }
              nextButton={
                <Button
                  disabled={step === questions.length - 1}
                  onClick={handleNext}
                >
                  Next <KeyboardArrowRight />
                </Button>
              }
            />
          </Paper>

          <Box sx={{ paddingBottom: 2}} />

          <CertificationList step={step} choices={choices} />

          <Box sx={{ paddingBottom: 2}} />
        </Grid>
      </Grid>

    </Box>
  );
}

export default CertificationPage;
