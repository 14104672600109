import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

const SustainabilityPage = () => {
  return (
    <Box>
      <Grid container columns={{ xs: 1, md: 2, lg: 3 }}>
        <Grid item xs={1}>
          <Box>
            <Typography variant="h3">Hello, I’m Wen.</Typography>
            <Typography variant="h4" sx={{ paddingBottom: 2 }}>
              Construction Engineer
            </Typography>
            <Typography>
              I'm an avid construction engineer with Master`s of sustainable
              urban planning.
            </Typography>
            <Box sx={{ paddingBottom: [1, 2] }} />

            <Stack direction="row" sx={{ marginY: 2 }}>
              <Link href="mailto:wen@nonetype.se">
                <IconButton aria-label="email">
                  <AlternateEmailIcon />
                </IconButton>
              </Link>
              <Link href="https://www.linkedin.com/in/wen-lu-b6773482/">
                <IconButton aria-label="linkedin">
                  <LinkedInIcon />
                </IconButton>
              </Link>
            </Stack>
          </Box>
        </Grid>

        <Grid
          item
          xs={1}
          lg={2}
          sx={{
            backgroundImage: `url('https://images.unsplash.com/photo-1486754735734-325b5831c3ad?auto=format&w=1024&dpr=${window.devicePixelRatio}')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            paddingBottom: "40%",
            clipPath: {
              xs: "polygon(0% 0%, 100% 5%, 100% 100%, 0% 95%)",
              md: "polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%)",
            },
          }}
        ></Grid>
      </Grid>

      <Box sx={{ paddingBottom: [5, 10] }} />

      <Grid
        container
        columns={{ xs: 1, md: 2 }}
        direction={{ xs: "column-reverse", md: "row" }}
      >
        <Grid
          item
          xs={1}
          sx={{
            backgroundImage:
              "url('https://www.gravatar.com/avatar/fcdde9b4b5ad443a5c15b56b10ea4d87.jpg?s=600')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            paddingBottom: ["100%", "100%", "50%"],
          }}
        ></Grid>
        <Grid item xs={1}>
          <Box
            sx={{
              paddingBottom: {
                xs: 5,
                md: 0,
              },
              paddingLeft: {
                md: 2,
              },
            }}
          >
            <Typography variant="h3" sx={{ paddingBottom: 2 }}>
              Who am I?
            </Typography>

            <Box>
              I am an environmental specialist and have a wide range of
              experience from building and construction sector. I have taken
              part in projects from both contractor and client side.
            </Box>
            <Box sx={{ paddingBottom: [1, 2] }} />

            <Box>
              I have working experience in multiple building and assesment
              methods such as Svanen, Miljöbyggnad, BREEAM SE, LEED and WELL.
              And management systems such as BF9K, ISO 14001 and ISO 9001.
            </Box>
            <Box sx={{ paddingBottom: [1, 2] }} />

            <Box>
              A large part of my work involves environmental coordination,
              follow-up in projects and developing strategic sustainability
              goals for companies and implementing them in the management
              system.
            </Box>
            <Box sx={{ paddingBottom: [1, 2] }} />
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ paddingBottom: [5, 10] }} />
    </Box>
  );
};

export default SustainabilityPage;
